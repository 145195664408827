// extracted by mini-css-extract-plugin
export const top = "utbot-desktop-module--top--Sgard";
export const main = "utbot-desktop-module--main--s8dDE";
export const detailsEditor = "utbot-desktop-module--detailsEditor--Sr8tQ";
export const detailsContainer = "utbot-desktop-module--detailsContainer--u3W8-";
export const codeEditor = "utbot-desktop-module--codeEditor--wGc00";
export const codeEditorContainer = "utbot-desktop-module--codeEditorContainer--jhtD1";
export const codeEditorsContainer = "utbot-desktop-module--codeEditorsContainer--IXuBx";
export const sourceCodeEditorcontainer = "utbot-desktop-module--sourceCodeEditorcontainer--VfaFh";
export const testsEditorContainer = "utbot-desktop-module--testsEditorContainer--YS8Tv";
export const alert = "utbot-desktop-module--alert--jWLzv";
export const generateAndRunTestsButtonContainer = "utbot-desktop-module--generateAndRunTestsButtonContainer--xWoaO";
export const generateAndRunTestsButton = "utbot-desktop-module--generateAndRunTestsButton---TpCy";
export const toolbarContainer = "utbot-desktop-module--toolbarContainer--WX2aO";
export const copyLinkButton = "utbot-desktop-module--copyLinkButton--wwfTM";
export const dropdownLanguages = "utbot-desktop-module--dropdownLanguages--4kA+Z";
export const dropdownContainer = "utbot-desktop-module--dropdownContainer--nQY1j";